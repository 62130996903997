<template>
  <div class="q-container">
    <div class="title">
      <ty-item-title :title="hanldeTitle()" :margin-left="true"></ty-item-title>
    </div>
    <div class="container-content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :disabled="disabled"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="问题类型：" prop="problemType">
          <el-radio-group
            v-model="form.problemType"
            size="medium"
            @change="problemTypeChange"
          >
            <el-radio-button
              v-for="(item, index) in problemTypeList"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="
            form.problemType !== 2 &&
            form.problemType !== 3 &&
            form.problemType !== 4
          "
          label="关联客户字段："
          prop="relatedUserFieldName"
        >
          <el-cascader
            ref="cascader"
            v-model="form.relatedUserFieldName"
            filterable
            :props="{
              emitPath: false,
              value: 'relatedUserFieldName',
              label: 'relatedUserFieldNote',
              children: 'secondaryList',
            }"
            placeholder="搜索字段"
            :options="relatedUserList"
            :show-all-levels="false"
            style="width: 361px"
            clearable
            @change="relatedUserChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="题型：" prop="type">
          <el-radio-group
            v-model="form.type"
            size="medium"
            :disabled="typeDisabled"
            @change="typeChange"
          >
            <el-radio
              v-for="(item, index) in typeListArr"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="问题内容：" prop="content">
          <el-input
            v-model="form.content"
            size="medium"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
            placeholder="请输入问题内容"
            :maxlength="30"
            show-word-limit
            style="max-width: 500px"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="form.problemType === 1"
          label="关联字典字段："
          prop="relatedTagId"
        >
          <el-select
            v-model="form.relatedTagId"
            placeholder="请选择"
            size="medium"
            filterable
            @change="dictChange"
          >
            <el-option
              v-for="item in relatedTagList"
              :key="item.dictionaryId"
              :label="item.dictionaryName"
              :value="item.dictionaryId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.problemType === 2"
          label="标签分类："
          prop="relatedTagId"
        >
          <el-cascader
            v-model="relatedTagId"
            :options="tagList"
            :show-all-levels="false"
            :props="{
              value: 'categoryId',
              label: 'categoryName',
              children: 'tags',
              checkStrictly: true,
            }"
            @change="tagChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          v-if="form.type === 0 || form.type === 1"
          label="答案选项："
          prop="options"
          :required="form.problemType !== 3 && form.problemType !== 4"
        >
          <div>
            <div v-if="form.problemType === 0" class="answer">
              <el-button
                type="text"
                icon="el-icon-plus"
                class="answer__title"
                :disabled="form.options.length >= 12"
                @click="onAdd"
                >添加选项</el-button
              >
              <el-tooltip
                class="item"
                effect="dark"
                content="选项数量最少2个，最多12个"
                placement="bottom"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </div>
            <div
              v-if="form.problemType !== 3 && form.problemType !== 4"
              class="answer-box"
            >
              <template v-if="form.problemType === 1 || form.problemType === 2">
                <div v-if="!dictList.length" class="answer-box--empty">
                  请先选择关联的字典或标签分类
                </div>
                <checkBox
                  v-if="dictList.length"
                  :key="form.relatedTagId"
                  :options="form.options"
                  :list="dictList"
                  :other-father-id="form.relatedTagId"
                  @change="checkBoxChange"
                />
              </template>
              <defined
                v-if="form.problemType === 0"
                :options="form.options"
                @change="checkBoxChange"
              />
            </div>
            <!-- <product
              v-if="form.problemType === 3"
              :options="form.options"
              @change="checkBoxChange"
            /> -->
            <el-link
              v-if="form.problemType === 3"
              :underline="false"
              type="warning"
              >答案选项默认为下单产品标签关联的样片</el-link
            >
            <el-link
              v-if="form.problemType === 4"
              :underline="false"
              type="warning"
              >答案选项默认为下单用户的亲友</el-link
            >
          </div>
        </el-form-item>
        <el-form-item v-if="!disabled" style="margin-top: 100px">
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-circle-check"
            @click="onSave"
            >确定</el-button
          >
          <el-button size="medium" @click="goBack">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
/**
 * 关联字典字段与关联标签分类是一个字段
 *
 */
import tyItemTitle from "@/components/ty-item-title/index.vue";
import {
  parentDictionarys,
  getTagCategoryList,
  getDictList,
  getCustomerData,
  addQuestionnaire,
  getIssueDetail,
  getTagDetails,
} from "@/api/questionnaire.js";
import checkBox from "@/views/questionnaireManage/addOrEditIssue/components/check-box/index.vue";
import defined from "@/views/questionnaireManage/addOrEditIssue/components/defined/index.vue";
// import product from "@/views/questionnaireManage/addOrEditIssue/components/product/index.vue";
export default {
  components: { tyItemTitle, checkBox, defined },
  data() {
    return {
      id: "",
      backType: "questionnaire",
      disabled: false,
      problemTypeList: [
        {
          label: "自定义",
          value: 0,
        },
        {
          label: "数据字典",
          value: 1,
        },
        {
          label: "标签库",
          value: 2,
        },
        {
          label: "拍摄产品",
          value: 3,
        },
        {
          label: "拍摄人",
          value: 4,
        },
      ],
      typeList: [
        {
          label: "单选题",
          value: 0,
        },
        {
          label: "多选题",
          value: 1,
        },
        {
          label: "日期编辑题",
          value: 2,
        },
        {
          label: "填空题",
          value: 3,
        },
        {
          label: "图片编辑题",
          value: 4,
        },
      ],
      relatedTagList: [],
      tagList: [],
      rules: {
        problemType: [
          { required: true, message: "请选择问题类型", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择题型", trigger: "change" }],
        content: [
          { required: true, message: "请输入问题内容", trigger: "blur" },
        ],
        relatedTagId: [
          {
            required: true,
            message: "请选择关联的标签/字典",
            trigger: "change",
          },
        ],
      },
      form: {
        problemType: 0,
        type: 0,
        relatedUserFieldName: "",
        relatedUserFieldNote: "",
        content: "",
        relatedTagId: "",
        relatedTagValue: "",
        options: [],
      },
      dictList: [],
      optionObj: {
        option: "",
        otherId: "",
        productUrl: "",
        productCode: "",
      },
      relatedUserList: [],
      relatedTagId: [], // 标签选中值，展示用
      typeDisabled: false,
    };
  },
  computed: {
    typeListArr() {
      if (this.form.problemType === 0) return this.typeList;
      return this.typeList.slice(0, 2);
    },
  },
  async created() {
    this.id = this.$route.query?.id || "";
    this.backType = this.$route.query?.listType || "questionnaire";
    this.disabled = !!this.$route.query?.disabled || false;

    await this.tagCategoryList();
    await this.getParentDictionarys();
    if (this.id) this.getIssueData();

    this.getCustomerDataList();
    this.problemTypeChange(this.form.problemType);
  },
  methods: {
    getIssueData() {
      getIssueDetail({ data: this.id }).then((res) => {
        console.log(res);
        this.form = res.data;
        this.relatedTagId = res.data.relatedTagId;
        if (!this.form.options) this.form.options = [];

        if (res.data.problemType === 1) {
          this.dictChange(res.data.relatedTagId, true);
        }
        if (res.data.problemType === 2) {
          console.log("这儿");
          this.tagChange(res.data.relatedTagId, true);
        }
      });
    },
    getCustomerDataList() {
      getCustomerData().then((res) => {
        console.log(res);
        this.relatedUserList = res.data;
      });
    },
    async tagCategoryList() {
      await getTagCategoryList({ data: {} }).then((res) => {
        this.tagList = res.data.map((e) => {
          e.disabled = true;
          e.tags = e.tags.map((el) => {
            return {
              categoryId: el.tagId,
              categoryName: el.tagName,
            };
          });
          console.log(e);
          return e;
        });
      });
    },
    getTagList(e) {
      getTagDetails({
        data: {
          tagId: e,
        },
      }).then((res) => {
        console.log(res);
        this.form.relatedTagId = res.data.tagId;
        this.form.relatedTagValue = res.data.tagName || "";
        this.dictList = res.data.tagValues;
      });
    },
    async getParentDictionarys() {
      await parentDictionarys({
        data: {
          dictionaryKey: "",
          dictionaryName: "",
          isQuestion: 1,
        },
      }).then((res) => {
        console.log(res);
        this.relatedTagList = res.data;
      });
    },
    hanldeTitle() {
      if (this.id) {
        return this.disabled ? "问题详情" : "编辑问题";
      }
      return "新增问题";
    },
    onAdd() {
      if (this.form.options.length >= 12)
        return this.$message.warning("选项数量最多12个！");
      this.form.options.push({ ...this.optionObj });
    },
    /**
     * 问题类型切换事件
     */
    problemTypeChange(e) {
      this.form.relatedTagId = "";
      this.form.relatedTagValue = "";
      this.form.options = [];
      this.dictList = [];
      // 自定义时默认添加两个答案项
      if (e === 0) {
        this.form.options.push(
          ...[
            {
              ...this.optionObj,
            },
            {
              ...this.optionObj,
            },
          ]
        );
      }
      // 只有自定义和数据字典有关联客户字段
      if (e === 2 || e === 3 || e === 4) {
        this.form.relatedUserFieldNote = "";
        this.form.relatedUserFieldName = "";
      }
      // 拍摄产品和拍摄人类型只有多选和单选题，且默认勾选多选
      if (e === 3 || e === 4) {
        this.form.type = 1;
      }
      this.typeDisabled = false;
      // 拍摄人的题型只能是多选
      if (e === 4) {
        this.typeDisabled = true;
      }
    },
    /**
     * 题型切换事件
     */
    typeChange(e) {
      if (e === 2 || e === 3 || e === 4) {
        this.form.options = [];
      } else {
        if (!this.form.options.length && this.form.problemType === 0) {
          this.form.options.push(
            ...[
              {
                ...this.optionObj,
              },
              {
                ...this.optionObj,
              },
            ]
          );
        }
      }
    },
    /**
     * 字典分类切换
     */
    dictChange(e, init = false) {
      const data = this.relatedTagList.find((el) => el.dictionaryId === e);
      this.form.relatedTagValue = data.dictionaryName;
      if (!init) this.$set(this.form, "options", []);
      getDictList({
        data: {
          parentId: e,
        },
        pageNumber: 1,
        pageSize: 20,
      }).then((res) => {
        console.log(res);
        this.dictList = res.data.content;
      });
    },
    /**
     * 标签分类切换
     */
    tagChange(e, init = false) {
      if (!e) return;
      if (Array.isArray(e)) {
        this.getTagList(e[e.length - 1]);
      } else {
        this.getTagList(e);
      }

      if (!init) this.$set(this.form, "options", []);
    },
    checkBoxChange(e) {
      console.log(e);
      this.$set(this.form, "options", e);
      console.log(this.form);
    },
    relatedUserChange() {
      const data = this.$refs.cascader.getCheckedNodes(true);
      this.form.relatedUserFieldNote = data[0]?.label || "";
    },
    /**
     * 校验答案选项是否满足2-12个
     */
    validateOptions() {
      // 这些是不需要录入答案选项的，可以直接放行
      if (
        this.form.problemType === 3 ||
        this.form.problemType === 4 ||
        (this.form.problemType === 0 &&
          (this.form.type === 2 ||
            this.form.type === 3 ||
            this.form.type === 4))
      ) {
        return true;
      }
      // 验证答案选项的数量是否满足要求
      if (this.form.options.length < 2) {
        this.$message.error("答案选项至少录入2个");
        return false;
      } else if (this.form.options.length > 12) {
        this.$message.error("答案选项至多录入12个");
        return false;
      }
      return true;
    },
    /**
     * 校验答案选项是否录入完整
     */
    hanldeOptions() {
      const { options, problemType, type } = this.form;
      for (const el in options) {
        console.log(el);
        const item = options[el];
        // 自定义题中单选与多选题可以验证option是否为空来判断是否录入完整，未录入完整则将其返回并结束当前方法
        if (problemType === 0 && (type === 0 || type === 1) && !item.option) {
          return el;
        }
      }

      // 能走到这儿说明已有答案选项是填写完整的
      return "success";
    },
    onSave() {
      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (!valid || !this.validateOptions()) return false;
        const option = this.hanldeOptions();
        if (option !== "success") {
          this.$message.error(`请将答案选项${Number(option) + 1}补充完整！`);
          return false;
        }
        console.log(this.form);
        addQuestionnaire({ data: this.form }).then((res) => {
          console.log(res);
          this.$message.success(`问题${this.id ? "编辑" : "新增"}成功！`);
          this.goBack();
        });
      });
    },
    goBack() {
      this.$router.push({
        name: "questionnaireManageIndex",
        query: { listType: "issue" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.q-container {
  background-color: #fff;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.container-content {
  flex: 1;
  // overflow-y: scroll;
  width: 100%;
  padding: 30px 50px;
  box-sizing: border-box;
}
// .demo-ruleForm {
//   // max-width: 700px;
// }
.answer {
  display: flex;
  align-items: center;
  &__plus {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2861e2;
    border-radius: 3px;
    font-size: 8px;
    color: #fff;
  }
  &__title {
    margin-right: 4px;
    // color: #2861e2;
    font-size: 14px;
  }
}
.answer-box {
  background-color: #f8f8f8;
  padding: 26px 20px;
  box-sizing: border-box;
}
.answer-box--empty {
  line-height: 1;
  font-size: 12px;
}
</style>
