<template>
  <div :key="list[0].categoryId">
    <el-checkbox
      v-for="(item, index) in list"
      :key="index"
      class="checkbox"
      :checked="isChecked(item)"
      @change="onChange(item)"
      >{{ item.dictionaryName || item.tagValue }}</el-checkbox
    >
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    otherFatherId: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {};
  },

  methods: {
    isChecked(item) {
      const have = this.options.find(
        (e) => e.otherId === (item.dictionaryId || item.id)
      );
      return !!have;
    },
    onChange(e) {
      let options = JSON.parse(JSON.stringify(this.options));
      console.log(e,options);
      const have = options.find(
        (el) => el.otherId === (e.dictionaryId || e.id)
      );
      if (have) {
        options = options.filter(
          (el) => el.otherId !== (e.dictionaryId || e.id)
        );
      } else {
        options.push({
          option: e.dictionaryName || e.tagValue,
          otherId: e.dictionaryId || e.id,
          otherFatherId: "",
          productUrl: "",
          productCode: "",
        });
      }
      console.log(options);
      this.$emit("change", options);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  width: 140px;
}
</style>
