<template>
  <div class="checkbox">
    <div v-for="(item, index) in options" :key="index" class="checkbox-item">
      <label for="" class="checkbox-item__label">选项{{ index + 1 }}：</label>
      <el-input
        type="text"
        placeholder="请输入选项内容"
        :value="item.option"
        maxlength="10"
        :disabled="elForm.disabled"
        show-word-limit
        size="medium"
        @input="onChange($event, index)"
      >
      </el-input>
      <i
        v-if="showDel"
        class="el-icon-remove checkbox-item__del"
        :class="elForm.disabled && 'checkbox-item__del--disabled'"
        @click="onDel(index)"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["elForm"],
  computed: {
    showDel() {
      return this.options.length > 2;
    },
  },
  methods: {
    onChange(e, index) {
      const options = this.options;
      options[index].option = e;
      this.$emit("change", options);
    },
    onDel(index) {
      if (this.elForm.disabled) return;
      const options = this.options;
      options.splice(index, 1);
      this.$emit("change", options);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.checkbox-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 20px;
}
.checkbox-item__label {
  width: 100px;
  color: #363f47;
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
  &::before {
    content: "*";
    color: #ff4949;
    margin-right: 4px;
  }
}
.checkbox-item__del {
  font-size: 16px;
  color: #e86247;
  margin-left: 6px;
  &--disabled {
    color: #c0c4cc;
  }
}
</style>
